import {
  Routes,
  Route,
} from 'react-router-dom';
import './App.css';
import { Suspense, lazy } from "react";

const Main = lazy(() => import("./pages/main"));

function App() {
  return (
    <Routes>
      <Route path="/" element={
        <Suspense>
          <Main />
        </Suspense>
      } />
    </Routes>
  );
}

export default App;

